.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.vh100 {
  min-height: 100vh;
}
.bg-white {
  background-color: white;
}
.reset-margin {
  margin: 1rem;
}
.sidebar {
  padding: 1rem 0rem 1rem 0rem;
  /* padding-right: 0; */
  text-align: left;
  margin-top: 1rem;
  margin-left: 1rem;
}
.main-content{
  padding: 2rem;
}
.firstForm {
  text-align: left;
}
.firstForm .ant-row {
  margin: 0.5rem 0rem;
}
.firstForm input:not(.ant-calendar-picker-input) {
  width: 75%;
  /* margin-bottom: 20px; */
  border-style: groove;
  border-radius: 3px;
  border: 1px solid;
  height: 2rem;
  border-color: #CAD1D5;
}
.firstForm textarea{
  margin-bottom: 20px;
}
.ant-radio-group{
  margin-bottom: 20px;
}
.firstForm label {
  display: block;
  margin-bottom: 0.3rem;
  margin-top: 0.5rem;
}
.steps-action {
  /* margin: 2rem 1rem 1rem 1rem; */
  text-align: right;
  border-top: 0.5px solid #CAD1D5;
  padding: 1rem 3rem 1rem 1rem;
  margin: 25px 0px 0px;
}
.ant-btn > .anticon + span, .ant-btn > span + .anticon {
  margin-left: 5px;
}
.flexIt {
  display: inline-flex;
}
.deptHeader {
  margin-top: 1rem;
}

/*Error*/
.is-invalid-input:not(:focus) {
    background-color: rgba(236, 88, 64, 0.1);
    border-color: #ec5840;
}
.form-error.is-visible {
    display: block;
}

.form-error {
    display: none;
    /* margin-top: 0.2rem; */
    /* margin-bottom: 1rem; */
    font-size: 0.75rem;
    font-weight: bold;
    position: relative;
    bottom: 0rem;
    color: #ec5840;
}
.is-visible {
    display: block !important;
}
.position-relative{
  position: relative;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}
.ant-input-group-wrapper {
  padding-left: 0px;
}
.ant-calendar-picker-input {
  height: 2rem;
  border-style: groove;
  border-radius: 3px;
  border: 1px solid;
  border-color: rgba(128, 128, 128, 0.5);
}
.ant-calendar-picker {
  width: 75%;
}
.tagss {
  margin-bottom: 1rem;
}
.ant-steps-item {
  margin: 0.5rem 0rem;
}
.ant-radio-button-wrapper-checked {
  /* background-color: #DEF1FF;
  border-color: #DEF1FF;
  color: white; */
}

.ant-radio-button-wrapper-checked {
    background: #DEF1FF;
    border-color: #40a9ff;
    color: #40a9ff;
    box-shadow: -1px 0 0 0 #40a9ff;
    z-index: 1;
}
::placeholder {
    color: lightgray;
    padding-left: 10px;
}
.heyFuckThis {
  border: 1px solid #CAD1D5;
  width: 55%;
  height: 100px;
  padding: 0.5rem 1rem;
  border-radius: 3px;
}
h1 {
  font-size: 1.5em;
}
/* Override default ant style */
.ant-steps-small .ant-steps-item-title{
  font-size: 14px;
  font-weight: 600;
}

.ant-steps-item-process {
  background-color: #eef2f9;
}
.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title{
  color:#1890ff;
  font-size: 16px;
}
.ant-steps-small .ant-steps-item-icon{
  margin-left: 1rem;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}
.ant-steps-item-process .ant-steps-item-content{
  border-right: 4px solid #1890ff;
  font-weight: 700;
  font-size: 14px;
}

.ant-steps-item{
display: flex!important;
align-items: center!important;
}
/* Disable the line in asteps */
.ant-steps .ant-steps-item .ant-steps-item-tail{
  display: none!important;
}
.ant-steps-vertical .ant-steps-item-content{
  display: flex;
  align-items: center;
  width:100%;
}
.header {
  /* background-color: #fff; */
  padding-left: 1rem;
}
.ant-menu-item {
  color: #fff !important;
  font-weight: 600;
}
.ant-menu-item:hover {
  background-color: transparent;
  color: #fff;
}
#printMe {
  visibility: hidden;
}
.ant-radio-group {
  margin-bottom: 0rem;
}
.poweredBy {
  display: inline !important;
  color: #1890ff !important;
}
.poweredBy:hover {
  color: #7ED321 !important;
  text-decoration: underline;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #7ED321;
  background-color: #7ED321;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
