body{
	font-family:sans-serif;
}
.single-page{
	        /* height: 842px; */
        /* width: 595px; */
        /* to centre page on screen*/
        margin-left: auto;
		margin-right: auto;
		min-height: 1000px;

/*
	max-width:595px;
	max-height:842px; */
	/* border: 1px solid red; */
	/* overflow: hidden; */

}
.pdf-page{
	/* margin:100px; */
	/* margin:100px auto; */
	/* margin: 25mm 25mm 25mm 25mm; */

	/* max-width:595px; */
	/* height:842px; */
	background-color:#fff;
	/* padding-top:50px; */
	/* padding-bottom:50px; */
}
.heading{
	font-weight: bold;
}
.is-center{
	margin:0 auto;

}
.text-center{
	text-align: center;
}

.text-left{
	text-align: left;
}

.text-right{
	text-align: right;
}

.underline{
	text-decoration: underline;
}

.field-name{
	margin-left:16px;
}

.mb-0{
	margin-bottom: 0;
}

.question{
	/* font-weight: bold; */
	font-size:12px;
}

.answer{
	font-weight: bold;
	text-transform: uppercase;
	/* font-size: 8px; */
}
@media print {

  }

  .page-break {
	page-break-inside: avoid;
}


.border-box{
	border: solid 1px gray;
	min-height: 1.5cm;
}

.bold{
	font-weight: bold;
}

@media print{
	@page{
		/* size:A4; */
	}
}

.inline-block{
	display:inline-block;
}
@page {
	/* size: 7in 9.25in; */
	/* margin: 27mm 16mm 27mm 16mm; */
 }

 .page1 .question{
	padding-left: 20px;
}

.page1 .main-question{
	padding-left: 0;
}


.page2 .public-sector-table .border-box{
	min-height:0.9cm;
	padding-left: 5px;
}

.page2 .private-sector-table .border-box{
	min-height:0.6cm;
	padding-left: 5px;
}

.page2 .private-sector-table .border-box p{
	margin-bottom: 0;
}

.page2 .public-sector-table {
	min-height:0.6cm;
	padding-left: 5px;
}

.page2 .public-sector-table  p{
	margin-bottom: 0;
}


.page2 .clinic-table .border-box{
	min-height:0.9cm;
	padding-left: 5px;
}
.page2 .clinic-table .border-box p{
	min-height:0.9cm;
	padding-left: 5px;
}

.page3 .center-table .border-box {
	min-height:1.5cm;
	padding-left: 5px;
}

.page3 .center-table  p{
	margin-bottom: 0;
}

.page3 .hospital-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page3 .hospital-table  p{
	margin-bottom: 0;
}

.page3 .lab-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page3 .lab-table  p{
	margin-bottom: 0;
}

.page3 .image-center-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page3 .image-center-table  p{
	margin-bottom: 0;
}

.page3 .blood-bank-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page3 .blood-bank-table  p{
	margin-bottom: 0;
}

.page3 .facilities-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page3 .facilities-table  p{
	margin-bottom: 0;
}

.page4 .allopathic-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page4 .allopathic-table  p{
	margin-bottom: 0;
}

.page4 .ayurveda-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page4 .ayurveda-table  p{
	margin-bottom: 0;
}

.page4 .unani-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page4 .unani-table  p{
	margin-bottom: 0;
}

.page4 .siddha-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page4 .siddha-table  p{
	margin-bottom: 0;
}

.page4 .homeopathy-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page4 .homeopathy-table  p{
	margin-bottom: 0;
}

.page4 .neturopathy-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page4 .neturopathy-table  p{
	margin-bottom: 0;
}

.page4 .yoga-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page4 .yoga-table  p{
	margin-bottom: 0;
}

.page4 .patient-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page4 .patient-table  p{
	margin-bottom: 0;
}

.page4 .patient-table  {
	margin-bottom: 8px;
}


.page5 .staff-table .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page5 .staff-table  p{
	margin-bottom: 0;
}

.page5 .staff-table  {
	margin-bottom: 8px;
}


.page-annex .border-box {
	min-height:0.7cm;
	padding-left: 5px;
}

.page-annex   p{
	margin-bottom: 0;
}



 @media print {

	/* body{
		background-color:black;
	} */

	.page1, .page2{
		/* background-color: skyblue; */
		/* margin-top: 5cm; */
		/* margin-bottom: 5cm; */
		min-height: 22cm;
	}

	.page1 .question-item {
		margin-top: 0.5cm;
	}


	/* .page2{
		margin-top: 2cm;
		margin-bottom: 2cm;
		min-height: 22cm;
	} */

	.page2 .question-item {
		margin-top: 0.5cm;
	}
	.page1, .page2, .page3, .page4, .page5 {
		page-break-before:always;
		margin-top: 2cm;
	}
	.page-annex {
		min-height: 1000px;
		margin-top: 2cm;
		/* padding-top:150px; */
		page-break-before:always;
	}


 }

.ml-10{
	margin-left:10px;
}

.not-applicable{
	/* position: absolute; */
    /* left: 25%; */
    /* bottom: 120%; */
    /* font-size: 50px; */
}
